@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sorts+Mill+Goudy:ital@0;1&family=Spectral:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.App {
  margin: 0;
  padding: 0;
  height: 100vh;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  line-height: 1.8;
  display: flex;
  flex-direction: row;
  // padding: 10vh 0vw;

  section {
    padding-bottom: 4em;
  }

  .nav {
    text-align: right;
    width: 22vw;
    min-width: 200px;
    padding-right: 4vw;
    margin-left: 8vw;
    line-height: 2em;

    .contact {
      margin-top: 1.4em;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 1em;
    }
  }

  .main {
    flex: 1;
    padding-right: 22vw;
    padding-left: 2vw;
    width: 75vw;
    height: 100%;
    overflow: auto;
  }

  svg {
    display: inline;
    font-size: 1.2em;
  }
}

@media only screen and (max-width: 740px) {
  .App {
    flex-direction: column;
    // padding: 8vh 0vw;

    .nav {
      padding: 0;
      text-align: center;
      margin: 0 auto;

      .navItems {
        display: none;
      }

      .contact {
        margin-top: 0em;
        justify-content: center;
      }
    }

    .main {
      width: 100%;
      padding: 1em 2em;
      margin: auto;
    }
  }
}